import {
  ref, reactive, inject, onMounted,
} from 'vue';
import { useStore } from 'vuex';

const defaultOpts = {
  formName: 'contact',
  captchaKey: null,
  data: {},
  successMsg: 'Das Kontaktformular wurde erfolgreich gesendet.',
  errorMsg: 'Beim Senden des Formulares ist ein Fehler aufgetreten.',
  captchaErrorMsg: 'Fehler beim Validieren des Captcha',
};

function snakeCase(key) {
  return key.replace(/[a-z][A-Z]/g, (_, m) => `${m[0]}_${m[1].toLowerCase()}`);
}

export default function useContactForm({
  data, errorMsg, successMsg, formName, captchaErrorMsg, captchaKey,
} = defaultOpts) {
  const recaptchaRef = ref(null);
  const formRef = ref(null);

  const formData = reactive(data);

  const store = useStore();

  const toast = inject('$toast');

  const sendForm = async (token) => {
    try {
      const form = new FormData();

      Object.entries(formData).forEach(([key, value]) => {
        form.set(snakeCase(key), value);
      });

      form.set('form', formName);
      form.set('g-recaptcha-response', token);
      const result = await fetch(
        `${store.state.router.base}/${store.state.router.locale}/ajax/forms/new_request/notify`,
        {
          method: 'POST',
          body: new URLSearchParams(form).toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      const response = await result.json();
      if (response.type === 'error') {
        console.error(response.message);
        toast.error(errorMsg);
      } else {
        toast.success(successMsg);
      }
    } catch (e) {
      console.error(e);
      toast.error(errorMsg);
    }
  };

  onMounted(() => {
    window.grecaptcha.render(recaptchaRef.value, {
      sitekey: captchaKey,
      callback: sendForm,
      errorCallback: () => toast.error(captchaErrorMsg),
    }, true);
  });

  const submitContactForm = async () => {
    if (formRef.value.reportValidity()) {
      window.grecaptcha.execute();
    }
  };

  return {
    recaptchaRef,
    formRef,
    formData,
    submitContactForm,
  };
}
