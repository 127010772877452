<template>
  <section class="contact part">
    <div class="constrain">
      <div class="row-12">
        <div class="col-12 md:col-10 md:offset-1 contact-block">
          <h1 v-html="payload.titel" />
          <div class="row-20">
            <div class="hidden md:block md:col-3">
              <span class="line" />
            </div>
            <div class="col-20 md:col-12">
              <span class="text" v-html="payload.text" />
            </div>

            <div class="col-20 md:col-15 md:offset-3">
              <recaptcha :ref="v => recaptchaRef = v?.$el" />
              <form ref="formRef" class="contact-form">
                <div class="services">
                  <div
                    v-for="service in payload.services"
                    :key="service.id"
                    class="checkbox"
                  >
                    <input
                      :id="`service-${service.id}`"
                      v-model="formData.services"
                      :value="service.titel"
                      type="checkbox"
                      name="services[]"
                    >
                    <label :for="`service-${service.id}`">
                      <span class="check"><tick class="tick" /></span>
                      {{ service.titel }}
                    </label>
                  </div>
                </div>
                <input
                  id="name"
                  v-model="formData.first_name"
                  type="text"
                  name="name"
                  required
                  :placeholder="payload.texts.names + ' *'"
                >
                <input
                  id="company"
                  v-model="formData.company"
                  type="text"
                  name="company"
                  :placeholder="payload.texts.company"
                >
                <input
                  id="mail"
                  v-model="formData.email"
                  type="text"
                  name="mail"
                  required
                  :placeholder="payload.texts.mail + ' *'"
                >
                <input
                  id="phone"
                  v-model="formData.phone"
                  type="text"
                  name="phone"
                  required
                  :placeholder="payload.texts.phone + ' *'"
                >
                <textarea
                  id="message"
                  v-model="formData.message"
                  name="message"
                  rows="1"
                  required
                  :placeholder="payload.texts.message"
                />
                <div class="flex items-end justify-end">
                  <div class="submit" @click.prevent="submitContactForm">
                    <label for="submit-btn">{{ payload.texts.send }}</label>
                    <icon-button id="submit-btn">
                      <arrow />
                    </icon-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import useContactForm from '@/composables/contactForm';
import Recaptcha from '@/components/utils/Recaptcha.vue';
import Tick from '@/assets/images/tick.svg?inline';
import Arrow from '@/assets/images/arrow.svg?inline';
import IconButton from '../utils/IconButton.vue';

export default {
  components: {
    Tick, Arrow, IconButton, Recaptcha,
  },
  props: { payload: { type: Object, default: () => ({}) } },
  setup(props) {
    return useContactForm({
      formName: 'contact',
      data: { services: [] },
      captchaKey: '6LfzPWQcAAAAAPyQ_pu1IOXz4hpJ5b934HOxAzD-',
      successMsg: props.payload.texts.success,
      errorMsg: props.payload.texts.error,
      captchaErrorMsg: props.payload.texts.captchaError,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

h1 {
  @include responsive('margin-bottom', px(15), px(133));
}

.text {
  position: relative;
  display: block;
}

.line {
  display: block;
  width: 100%;
  height: 1px;
  background: black;

  @include responsive('margin-top', px(11), px(13));
}

.contact-form {
  --column-min-width: 480px;
  display: grid;
  grid-column-gap: px(86);
  @include responsive('grid-template-columns', 1fr, 1fr 1fr);
  @include responsive('grid-row-gap', px(45), px(72));
  @include responsive('margin-top', px(39), px(110));
  @include responsive('margin-bottom', px(55), px(189));
}

.services {
  display: grid;
  grid-column-gap: px(86);
  @include responsive('grid-column', span 1, span 2);
  @include responsive('grid-template-columns', 1fr, 1fr 1fr);
  @include responsive('grid-row-gap', px(20), px(29));
  @include responsive('margin-bottom', px(60), px(160));
}
.submit {
  @apply text-primary;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  & > * {
    height: auto;
  }
  label {
    pointer-events: none;
    @include responsive('margin-right', px(21), px(23));
  }

  &:hover :deep(.icon-button) {
    @apply bg-primary;
    @apply text-white;
  }
}
</style>
